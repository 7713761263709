import Block from "./Block";
import {getRelativeLink} from "../utils/string-tools";
import getComponents from "../pages/getComponents";
import { Route } from "react-router-dom";
import MainLayout from "../layout/MainLayout";

class Page {
    id: number;
    draft: boolean;
    priv: boolean;
    link: string;
    title: string;
    blocks: Block[];

    constructor(id: number, draft: boolean, priv: boolean, link: string, title: string, blocks: Block[]) {
        this.id = id;
        this.draft = draft;
        this.priv = priv;
        this.link = link;
        this.title = title;
        this.blocks = blocks;
    }

    public static fromApiResponse(data: any) {
        const pages = [] as Page[];
        data.forEach((d: any) => {
            const blocks = d.acf.blocks ? d.acf.blocks.map((block: any) => new Block(block.acf_fc_layout, block)) : [];
            pages.push(new Page(
                d.ID,
                d.post_status === 'draft',
                d.post_status === 'private',
                getRelativeLink(d.link), d.post_title, blocks)
            )
        })
        return pages;
    }

    public toRoute() {
        const components = getComponents(this.blocks);
        // const components = [<Box sx={{height: "550px", width: "100%", backgroundColor:"blue"}}/>,<Box sx={{height: "750px", width: "100%", backgroundColor:"green"}}/>]
        return <Route key={this.title} path={this.link} element={<MainLayout pageTitle={this.title}>{components}</MainLayout>}/>
    }
}

export default Page;
