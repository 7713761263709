import {getRelativeLink} from "../utils/string-tools";

export class MenuItem {
    title: string;
    url: string;
    external: boolean;
    newPage: boolean;
    subMenu: MenuItem[] | undefined;

    constructor(title: string, url: string, external: boolean, newPage: boolean = false, subMenu?: MenuItem[]) {
        this.title = title;
        this.url = url;
        this.external = external;
        this.newPage = newPage;
        this.subMenu = subMenu;
    }
}

class Menu{
    menuItems: MenuItem[];

    constructor(menuItems: MenuItem[]) {
        this.menuItems = menuItems;
    }

    public static fromApiResponse(data: any) {
        const {acf} = data.find((d: any) => d.post_parent === 0);
        if(acf.download_main_menu) {
            const menuParent = data.filter((m: any) => m.post_parent === 0);
            const menuChild = menuParent[0].mainMenu.filter((d: any) => d.menu_item_parent === '0');
            const menu = menuChild.map((link: any) => {
                const subMenus = menuParent[0].mainMenu.filter((m: any) => m.menu_item_parent === link.ID.toString())
                const subMenusResult = subMenus.map((item: any) => {
                    return new MenuItem(item.title, item.url, true, item.target);
                })
                return new MenuItem(link.title, link.url, true, link.target, subMenusResult);
            })
            return new Menu(menu);
        } else {
            const menu = acf.menu ? acf.menu.map((mainItem: any) => {
                const subMenus = mainItem.submenu && mainItem.submenu.map((subItem: any) => {
                    const isExternal = subItem.sub_link_type === 'external'
                    const link = isExternal ? subItem.external_sub_link : getRelativeLink(subItem.internal_sub_link)
                    return new MenuItem(subItem.sub_link_name, link, isExternal, subItem.new_sub_tab);
                });
                const isExternal = mainItem.link_type === 'external'
                const link = isExternal ? mainItem.external_link : getRelativeLink(mainItem.internal_link)
                return new MenuItem(mainItem.link_name, link, isExternal, mainItem.new_tab, subMenus);
            }) : []
            return new Menu(menu);
        }
    }

}

export default Menu;
