const YoutubeIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M21.3903 4.12341C22.4182 4.40027 23.2288 5.21069 23.5055 6.23882C24.0197 8.11674 23.9999 12.0312 23.9999 12.0312C23.9999 12.0312 23.9999 15.9256 23.5057 17.8037C23.2288 18.8317 22.4184 19.6423 21.3903 19.9189C19.5122 20.4133 12 20.4133 12 20.4133C12 20.4133 4.50731 20.4133 2.60961 19.8993C1.58148 19.6225 0.771054 18.8119 0.4942 17.7839C0 15.9256 0 12.0114 0 12.0114C0 12.0114 0 8.11674 0.4942 6.23882C0.770871 5.21087 1.60125 4.38049 2.60943 4.10382C4.48753 3.60944 11.9998 3.60944 11.9998 3.60944C11.9998 3.60944 19.5122 3.60944 21.3903 4.12341ZM15.855 12.0114L9.60797 15.6094V8.41336L15.855 12.0114Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default YoutubeIcon
