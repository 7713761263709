import Grid from "@mui/material/Grid";
// @ts-ignore
import whiteLogo from "../assets/white.svg";
// @ts-ignore
import ccLogo from "../assets/cc_logos.png";
import {Box, Divider, styled, Typography} from "@mui/material";
import {useContext} from "react";
import ContentContext from "../store/content-context";
import DOMPurify from "dompurify";
import FacebookIcon from "../ui/icons/FacebookIcon";
import InstagramIcon from "../ui/icons/InstagramIcon";
import TwitterIcon from "../ui/icons/TwitterIcon";
import YoutubeIcon from "../ui/icons/YoutubeIcon";
import {ContactCard as ContactCardType, Menu as MenuType} from "../model/Settings";
import MailIcon from "../ui/icons/MailIcon";
import PhoneIcon from "../ui/icons/PhoneIcon";
import LinkedinIcon from "../ui/icons/LinkedinIcon";

const Logo = styled("img")({
    height: "100%",
})

const Email = styled("a")({
    textDecoration: "none",
    color: "white",
    fontSize: "17px",
})

const SocialIcons = styled("ul")({
    marginTop: "24px",
    listStyle: "none",
    marginBottom: 0,
    paddingLeft: 0,

    "li": {
        display: "inline-block",
        marginRight: "1.5rem",

        "a": {display: "flex"},
        "&:last-child": {marginRight: 0}
    },

    "a": {
        "svg": {width: "24px"},
        "svg path": {fill: "white"},

        "&:hover": {
            "svg path": {fill: "#0049B3"}
        }
    }
})

const SocialMedia = () => {
    const {options: {socialLinks}} = useContext(ContentContext);

    return <Grid item container>
        <SocialIcons>
            {socialLinks.facebook && <li>
                <a href={socialLinks.facebook} rel="noreferrer" target="_blank">
                    <FacebookIcon/>
                </a>
            </li>}
            {socialLinks.instagram && <li>
                <a href={socialLinks.instagram} rel="noreferrer" target="_blank">
                    <InstagramIcon/>
                </a>
            </li>}
            {socialLinks.twitter && <li>
                <a href={socialLinks.twitter} rel="noreferrer" target="_blank">
                    <TwitterIcon/>
                </a>
            </li>}
            {socialLinks.youtube && <li>
                <a href={socialLinks.youtube} rel="noreferrer" target="_blank">
                    <YoutubeIcon/>
                </a>
            </li>}
            {socialLinks.linkedin && <li>
                <a href={socialLinks.linkedin} rel="noreferrer" target="_blank">
                    <LinkedinIcon/>
                </a>
            </li>}
        </SocialIcons>
    </Grid>
}

const ContactCardIcon = styled("div")({
    display: "flex",
    padding: "8px",
    borderRadius: "100%",
    background: "#0049B3",

    "svg": {
        width: "16px",
        height: "16px",
        "path": {fill: "white"}
    }
})

type ContactCardProps = {
    contact: ContactCardType;
}

const ContactCard = ({contact}: ContactCardProps) => {
    const {zoom} = useContext(ContentContext);

    return <Grid item container xs={12} sm={6} md={3} flexDirection={"column"}>
        <Typography variant={"h6"} sx={{
            fontSize: 12 * zoom + "px",
            color: "white",
            mb: "8px",
            fontWeight: 400,
            letterSpacing: "0.3px",
            lineHeight: 1.5,
            textAlign: "start"
        }} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(contact.position)}}/>
        <Typography variant={"h4"} sx={{
            fontSize: 21 * zoom + "px",
            color: "white",
            mb: "8px",
            fontWeight: 600,
            letterSpacing: "0.2px",
            lineHeight: 1.334,
            textAlign: "start"
        }} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(contact.name)}}/>
        {contact.email &&
            <Box sx={{display: "flex", alignItems: "center", mb: "8px", textDecoration: "none"}} component="a"
                 target="_blank"
                 href={"mailto:" + contact.email}>
                <ContactCardIcon><MailIcon/></ContactCardIcon>
                <Typography sx={{
                    fontSize: 12 * zoom + "px !important",
                    fontWeight: 400,
                    lineHeight: "18px",
                    letterSpacing: "0.3px",
                    paddingLeft: "16px",
                    color: "white",
                }}>
                    {contact.email}
                </Typography>
            </Box>}
        {contact.phoneNumber &&
            <Box sx={{display: "flex", alignItems: "center", mb: "8px", textDecoration: "none"}} component="a"
                 target="_blank"
                 href={"tel:" + contact.phoneNumber}>
                <ContactCardIcon><PhoneIcon/></ContactCardIcon>
                <Typography sx={{
                    fontSize: 12 * zoom + "px !important",
                    fontWeight: 400,
                    lineHeight: "18px",
                    letterSpacing: "0.3px",
                    paddingLeft: "16px",
                    color: "white",
                }}>
                    {contact.phoneNumber}
                </Typography>
            </Box>}
    </Grid>
}

type MenuItemProps = {
    menu: MenuType;
}
const MenuItem = ({menu}: MenuItemProps) => {
    const {contrast, zoom} = useContext(ContentContext);

    return <Grid xs={12} sm={4} item container flexDirection={"column"}>
        <Typography sx={{fontSize: 17 * zoom + "px", color: "white", mb: "24px", textAlign: "start"}}>{menu.title}</Typography>
        {
            menu.links.map(({link}, index) => {
                return <Box sx={{
                    fontSize: 12 * zoom + "px",
                    color: contrast ? "white" : "#dfecfe",
                    mb: "12px",
                    textAlign: "start",
                    textDecoration: "none",
                    ":hover": {textDecoration: "underline"}
                }}
                            component={"a"} href={link.url} target={link.target != "" ? link.target : "_self"}
                            rel="noreferrer" key={index}>
                    {link.title}
                </Box>
            })
        }
    </Grid>
}
const Menu = () => {
    const {settings: {footer: {menu: footerMenu}}, options: {menu}} = useContext(ContentContext);
    const menuItems = footerMenu ? footerMenu : menu;
    return <Grid item container rowGap={"48px"} mb={{xs:"48px", md:"80px"}}>
        {menuItems.map((m, index) => <MenuItem menu={m} key={index}/>)}
    </Grid>
}

const Footer = () => {
    const {settings: {logo, footer}, options, contrast, zoom} = useContext(ContentContext);
    const contactCards = footer.contactCards && footer.contactCards.length > 0 ? footer.contactCards : options.contact ? options.contact.contactCards : [];

    return <Grid component={'section'} container justifyContent={'center'}
                 sx={{backgroundColor: contrast ? "black" : "#1B1D22", textAlign: 'center', gap: '40px', padding: '80px 40px 0 40px'}}>
        {options.contact && <Grid container item flexDirection={'column'} sx={{maxWidth: '1176px', rowGap: "48px"}}>
            <Grid container item sx={{maxHeight: "64px", columnGap: "50px"}}>
                {footer.logoFooter ? <Logo src={footer.logoFooter} alt={"logo"}/> : ''}
                <Logo src={logo} alt={"logo"}/>
            </Grid>
            <Grid item container pb={"96px"} rowGap={"48px"} sx={{borderBottom: ".5px solid white"}}>
                <Grid xs={12} md={6}  item container flexDirection={'column'} alignItems={"start"}>
                    {options.contact && <Typography variant={"h4"} sx={{
                        fontSize: 21 * zoom + "px",
                        color: "white",
                        fontWeight: 600,
                        textAlign: "start",
                        letterSpacing: "0.2px",
                        lineHeight: 1.334,
                        mb: "8px"
                    }} dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(options.contact.address)}}/>}
                    <Email href="'mailto:'+contact.email">{options.contactEmail}</Email>
                    <SocialMedia/>
                </Grid>
                {contactCards.map((contact, index) => <ContactCard contact={contact} key={index}/>)}
            </Grid>
            <Menu/>
            <Grid item container mt={"24px"} justifyContent={"space-between"} rowGap={"48px"}>
                <Grid item order={{ xs: 2, md: 1 }}>
                    <Typography sx={{
                        fontSize: 12 * zoom + "px !important",
                        color: contrast ? "white" : "#8e9eb6",
                        marginRight: "auto",
                        textAlign: "start"
                    }}>© Stowarzyszenie Cyfrowy Dialog 2020. Design &amp; code by &nbsp;
                        <Box sx={{
                            fontSize: 12 * zoom + "px",
                            color: contrast ? "white" : "#8e9eb6",
                            textDecoration: 'none',
                            ":hover": {textDecoration: 'underline'}
                        }}
                             component={"a"}
                             href="https://expansja.pl/" target="_blank">
                            Expansja Advertising
                        </Box>
                    </Typography>
                </Grid>
                <Grid item container xs={12} md={6} rowGap="10px" order={{ xs: 1, md: 2 }} justifyContent={{xs:"start", md:"end"}}>
                    <Grid item xs={12} md={"auto"} sx={{
                        fontSize: 12 * zoom + "px",
                        color: contrast ? "white" : "#8e9eb6",
                        textDecoration: 'none',
                        textAlign: "start",
                        paddingRight: "24px",
                        ":hover": {textDecoration: 'underline'}
                    }}
                         component={"a"}
                         href={options.docs.privacyPolicy.url} target="_blank">
                        {options.docs.privacyPolicy.title}
                    </Grid>
                    <Divider orientation={"vertical"} flexItem sx={{backgroundColor: "white"}}/>
                    <Grid item xs={12} md={"auto"} sx={{
                        fontSize: 12 * zoom + "px",
                        color: contrast ? "white" : "#8e9eb6",
                        textDecoration: 'none',
                        textAlign: "start",
                        padding: {xs:"0 24px 0 0", md:"0 24px"},
                        ":hover": {textDecoration: 'underline'}
                    }}
                         component={"a"}
                         href={options.docs.cookies.url} target="_blank">
                        {options.docs.cookies.title}
                    </Grid>
                    <Divider orientation={"vertical"} flexItem sx={{backgroundColor: "white"}}/>
                    <Grid item xs={12} md={"auto"} sx={{
                        fontSize: 12 * zoom + "px",
                        color: contrast ? "white" : "#8e9eb6",
                        textDecoration: 'none',
                        textAlign: "start",
                        padding: {xs:"0 24px 0 0", md:"0 24px"},
                        ":hover": {textDecoration: 'underline'}
                    }}
                         component={"a"}
                         href={options.docs.accessibility.url} target="_blank">
                        {options.docs.accessibility.title}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item container alignItems={"start"} mb={"48px"} rowGap={"10px"}>
                <Grid item sx={{
                    padding: {xs: 0, sm:"0 12px"}
                }}
                     component="img" src={ccLogo} alt={"cc-logo"}/>
                <Grid item xs={12} sm={6}>
                    <Typography sx={{
                        textAlign: "start",
                        fontSize: 12 * zoom + "px !important",
                        lineHeight: 1.5,
                        letterSpacing: "0.3px",
                        padding: {xs: 0, sm:"0 12px"},
                        color: contrast ? "white" : "#8e9eb6"
                    }}>
                        Wszystkie treści publikowane w serwisie są udostępniane na licencji Creative
                        Commons Uznanie Autorstwa 4.0 Międzynarodowe, o ile nie jest to stwierdzone inaczej
                    </Typography>
                </Grid>
            </Grid>
        </Grid>}
    </Grid>
}

export default Footer;
