import {Box, useTheme} from "@mui/material";

type Props = {
    content: any;
    color?: any;
    bckColor?: any;
}

const Button = ({content, color, bckColor}: Props) => {
    const {palette} = useTheme();

    return (
        <Box component={'a'} href={content.url} target={content.target} sx={{borderRadius: '40px', fontSize: '20px', textAlign: 'center', '&:hover': {filter: 'brightness(.7)', cursor: 'pointer'}, textDecoration: 'none', backgroundColor: bckColor ? bckColor : palette.primary.main, color: color ? color : palette.secondary.main, padding: {xs: '15px 25px', sm: '20px 48px'}}}>
            {content.title}
        </Box>
    )
}

export default Button;
