import Page from "../model/Page";
import {getRequest} from "./api";
import Settings from "../model/Settings";
import Menu from "../model/Menu";
import Options from "../model/Options";

export const getMainPageAndSettings = async (slug: string, pageId: string | null) => {
    const params = slug !== '' ? `?slug=${slug}` : pageId ? `?page_id=${pageId}` : ''
    const {data} = await getRequest(`/ex/landing-page${params}`, (error) => {});
    return {pages:Page.fromApiResponse(data), settings: Settings.fromApiResponse(data), menu: Menu.fromApiResponse(data)};
}

export const getOptions = async () => {
    const data = await getRequest(`/acf/v3/options/option`, (error) => {});
    return Options.fromApiResponse(data)
}
