
type Props = {
    color?: any;
}

const ArrowLeft = ({color}: Props) => {
    return (
        <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.473192 7.88703C0.2286 7.68698 0.2286 7.31302 0.473192 7.11297L8.18344 0.806657C8.50992 0.539625 9 0.77191 9 1.19369L9 13.8063C9 14.2281 8.50992 14.4604 8.18344 14.1933L0.473192 7.88703Z" fill={color ? color : '#fff'} />
            <rect x="8" y="6" width="12" height="3" rx="1" fill={color ? color : '#fff'} />
        </svg>
    )
}

export default ArrowLeft
