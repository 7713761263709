import {createContext, ReactNode, useReducer} from "react";
import Page from "../model/Page";
import Settings from "../model/Settings";
import Menu from "../model/Menu";
import Options from "../model/Options";

type ContentContextType = {
    pages: [],
    menu: Menu,
    zoom: number,
    settings: Settings,
    options: Options,
    contrast: boolean,
    switchContrast: () => void,
    zoomIn: () => void,
    zoomOut: () => void,
    zoomReset: () => void,
    setPages: (pages: Page[]) => void,
    setMenu: (menu: Menu) => void,
    setSettings: (settings: Settings) => void,
    setOptions: (options: Options) => void,
};

type ActionType = {
    type: string;
    value?: any;
};

const SET_PAGES = "setPages";
const SET_MENU = "setMenu";
const SET_SETTINGS = "setSettings";
const SET_OPTIONS = "setOptions";
const ZOOM_IN = "zoomIn";
const ZOOM_OUT = "zoomOut";
const ZOOM_RESET = "zoomReset";
const SWITCH_CONTRAST = "switchContrast";

const INITIAL_SETTINGS = {
    logo: "",
    logoUrl: "",
    colors: {primary: "#0099FF", secondary: "#000000", paggination: '#0049b3'},
    typography: {h1: 32, h2: 28, h3: 20, h4: 16, h5: 15, h6: 13, body1: 14, body2: 12, button: 20},
    footer: {contactCards: [], menu: [], logoFooter: ""}
}

const INITIAL_CONTENT_STATE: ContentContextType = {
    pages: [],
    menu: {} as Menu,
    contrast: false,
    switchContrast: () => {},
    zoom: 1,
    zoomIn: () => {},
    zoomOut: () => {},
    zoomReset: () => {},
    settings: INITIAL_SETTINGS as Settings,
    options: {} as Options,
    setPages: (pages: Page[]) => {},
    setMenu: (menu: Menu) => {},
    setSettings: (settings: Settings) => {},
    setOptions: (options: Options) => {},
};

const calculateFonts = ({typography}: Settings, zoom: number) => {
    return {
        h1: typography.h1 * zoom,
        h2: typography.h2 * zoom,
        h3: typography.h3 * zoom,
        h4: typography.h4 * zoom,
        h5: typography.h5 * zoom,
        h6: typography.h6 * zoom,
        body1: typography.body1 * zoom,
        body2: typography.body2 * zoom,
        button: typography.button * zoom,
    }
}

const contentReducer = (state: ContentContextType, action: ActionType): ContentContextType => {
    switch (action.type) {
        case SET_PAGES: {
            return {
                ...state,
                pages: action.value,
            };
        }
        case SET_MENU: {
            return {
                ...state,
                menu: action.value,
            };
        }
        case SET_OPTIONS: {
            return {
                ...state,
                options: action.value,
            };
        }
        case SET_SETTINGS: {
            return {
                ...state,
                settings: action.value,
            };
        }
        case SWITCH_CONTRAST: {
            return {
                ...state,
                contrast: !state.contrast,
            };
        }
        case ZOOM_IN: {
            let zoom = state.zoom
            let typography = state.settings.typography
            if(zoom < 1.5){
                zoom = state.zoom + 0.125
                typography = calculateFonts(INITIAL_SETTINGS, zoom)
            }
            console.log(zoom);
            return {
            ...state,
                    zoom: zoom,
                    settings: {...state.settings, typography}
            };
        }
        case ZOOM_OUT: {
            let zoom = state.zoom
            let typography = state.settings.typography
            if(zoom > 0.75){
                zoom = state.zoom - 0.125
                typography = calculateFonts(INITIAL_SETTINGS, zoom)
            }
            console.log(zoom);

            return {
                ...state,
                zoom: zoom,
                settings: {...state.settings, typography}
            };
        }
        case ZOOM_RESET: {
            return {
                ...state,
                zoom: 1,
                settings: {...state.settings, typography: INITIAL_SETTINGS.typography}
            };
        }
        default:
            return state;
    }
};

export const ContentContextProvider = ({children} : {children: ReactNode} ) => {
    const [contentState, dispatch] = useReducer(contentReducer, INITIAL_CONTENT_STATE, undefined);

    const setPages = (pages: Page[]) => {dispatch({type: SET_PAGES, value: pages});};
    const setMenu = (menu: Menu) => {dispatch({type: SET_MENU, value: menu});};
    const setSettings = (settings: Settings) => {dispatch({type: SET_SETTINGS, value: settings});};
    const setOptions = (options: Options) => {dispatch({type: SET_OPTIONS, value: options});};
    const zoomIn = () => dispatch({type: ZOOM_IN})
    const zoomOut = () => dispatch({type: ZOOM_OUT})
    const zoomReset = () => dispatch({type: ZOOM_RESET})
    const switchContrast = () => dispatch({type: SWITCH_CONTRAST})

    const contextValue: ContentContextType = {
        ...contentState,
        setPages: setPages,
        setMenu: setMenu,
        setSettings: setSettings,
        setOptions: setOptions,
        zoomIn: zoomIn,
        zoomOut: zoomOut,
        zoomReset: zoomReset,
        switchContrast: switchContrast
    };
    return <ContentContext.Provider value={contextValue}>{children}</ContentContext.Provider>;
};

const ContentContext = createContext<ContentContextType>({
    ...INITIAL_CONTENT_STATE,
});

export default ContentContext;
