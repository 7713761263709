import {createTheme, responsiveFontSizes, ThemeProvider} from "@mui/material";
import {useContext, useEffect, useState} from "react";
import {getMainPageAndSettings, getOptions} from "./api/content-api";
import ContentContext from "./store/content-context";
import Maintenance from "./pages/Maintenance";
import ScrollToTop from "./pages/ScrollToTop";
import {Route, Routes, useSearchParams} from "react-router-dom";
import Page from "./model/Page";
import Redirect from "./pages/Redirect";
import Preview from "./pages/Preview";

function App() {
    const [loaded, setLoaded] = useState(false);
    const {setSettings, setOptions, setPages, setMenu, pages, settings} = useContext(ContentContext);
    const defaultUrl = process.env.REACT_APP_DOMAIN ? process.env.REACT_APP_DOMAIN : '/';
    const [searchParams] = useSearchParams();
    const pageId = searchParams.get("post_id")

    useEffect(() => {
        let slug = window.location.pathname.split('/')[1]
        getOptions()
            .then((options) => {
                setOptions(options);
            })
            .catch(error => {
                console.log(error);
            })
        getMainPageAndSettings(slug, pageId)
            .then(({pages, settings, menu}) => {
                setSettings(settings);
                setPages(pages);
                setMenu(menu);
            })
            .catch(error => {
                console.log(error);
            })
            .finally(() => setLoaded(true))
    }, [])

    let theme = createTheme({
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 992,
                lg: 1200,
                xl: 1536,
            }
        },
        palette: {
            primary: {
                main: settings.colors.primary,
                light: settings.colors.paggination,
                // dark: "#707070",
            },
            secondary: {
                main: settings.colors.secondary,
                // light: "#9ba037",
                // dark: '#424242'
            },
        },
        typography: {
            fontSize: settings.typography.body1,
            fontFamily: [
                'Exo', 'sans-serif'
            ].join(','),
            h1: {
                fontSize: settings.typography.h1,
                fontWeight: 600
            },
            h2: {
                fontSize: settings.typography.h2,
                fontWeight: 600
            },
            h3: {
                fontSize: settings.typography.h3,
                fontWeight: 600
            },
            h4: {
                fontSize: settings.typography.h4,
            },
            h5: {
                fontSize: settings.typography.h5,
            },
            h6: {
                fontSize: settings.typography.h6,
            },
            body1: {
                fontSize: settings.typography.body1,

            },
            body2: {
                fontSize: settings.typography.body2,

            },
            button: {
                fontSize: settings.typography.button,
                textTransform: 'none',
                fontWeight: 600
            },
        },
    });

    theme = responsiveFontSizes(theme);

    return (
        <ThemeProvider theme={theme}>
            <Maintenance>
                <ScrollToTop>
                    <Routes>
                        {loaded && <Route key={"default"} path={"/"} element={pageId ? <Preview pageId={pageId}/> : <Redirect url={defaultUrl}/>}/>}
                        {pages.flatMap((page: Page) => page.toRoute())}
                        {loaded && <Route key={"default"} path={"*"} element={<Redirect url={defaultUrl}/>}/>}
                    </Routes>
                </ScrollToTop>
            </Maintenance>
        </ThemeProvider>

    );
}

export default App;
