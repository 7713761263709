import {Grid, Typography} from "@mui/material";
import Wysiwyg from "./WYSIWYG";
import Button from "../ui/Button";
import React from "react";

type Props = {
    content: any;
}

const TextBox = ({content}: Props) => {

    return (
        <Grid component={'section'} container justifyContent={'center'} flexDirection={'column'} sx={{backgroundColor: content.backgroundColor, textAlign: 'center', gap: '40px', padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`}}}>
            {content.title ? <Typography variant={"h2"} color={content.colorHeading}>{content.title}</Typography> : ''}
            {content.scheme === 'two' ?
                (
                    <Grid container item sx={{maxWidth: '1176px', width: '100%', margin: '0 auto', gap: {xs: '20px', md: '48px'}, textAlign: 'left'}} justifyContent={'space-between'}>
                        <Grid item xs={12} sm={5.5} md={5.5}>
                            {content.description ?
                                <Wysiwyg children={content.description} color={content.colorText} />
                                : ''
                            }
                        </Grid>
                        <Grid item xs={12} sm={5.5} md={5.5}>
                            {content.descriptionSecond ?
                                <Wysiwyg children={content.descriptionSecond} color={content.colorText} />
                                : ''
                            }
                        </Grid>
                        {
                            content.button ? <Grid item xs={12} sx={{marginTop: '20px', marginBottom: '20px', textAlign: 'center'}}><Button content={content.button} bckColor={content.colorButton} color={content.colorFontButton} /></Grid> : ''
                        }
                    </Grid>
                )
                :
                (
                    <Grid container item sx={{maxWidth: '976px', width: '100%', margin: '0 auto', gap: '48px', textAlign: 'left'}} justifyContent={'space-between'}>
                        <Grid item xs={12}>
                            {content.description ?
                                <Wysiwyg children={content.description} color={content.colorText} />
                                : ''
                            }
                            {
                                content.button ? <Grid item xs={12} sx={{marginTop: '40px', marginBottom: '20px', textAlign: 'center'}}><Button content={content.button} bckColor={content.colorButton} color={content.colorFontButton} /></Grid> : ''
                            }
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    )
}

export default TextBox;
