import {useContext} from "react";
import ContentContext from "../store/content-context";
import {styled} from "@mui/material";
import DOMPurify from "dompurify";

type Props = {
    children: any;
    color?: any;
    fontSize?: any;
    lineHeight?: any;
}

const WYSIWYG = ({children, color, fontSize, lineHeight}: Props) => {
    const {settings: {colors, typography}} = useContext(ContentContext);

    const StyledDiv = styled("div")(({theme}) => ({
        color: color ? color : colors.primary,
        fontFamily: 'Exo',
        '& h1': {fontSize: typography.h1 + "px"},
        '& h2': {fontSize: typography.h2 + "px"},
        '& h3': {fontSize: typography.h3 + "px"},
        '& h4': {fontSize: typography.h4 + "px"},
        '& h5': {fontSize: typography.h5 + "px"},
        '& h6': {fontSize: typography.h6 + "px"},
        '& p': {
            fontSize: fontSize === 'body2' ? typography.body2 + "px" : typography.body1 + "px",
            margin: 0,
            lineHeight: lineHeight ? lineHeight : '28px'
        },
    }))

    return <StyledDiv dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(children)}}/>
}

export default WYSIWYG;
