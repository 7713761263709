import {Grid, Box, Typography} from "@mui/material";
import Wysiwyg from "./WYSIWYG";

type Props = {
    content: any;
}

const IconList = ({content}: Props) => {

    return (
        <Grid component={'section'} container justifyContent={'center'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={'center'} alignItems={'center'} flexDirection={'column'} sx={{maxWidth: '1176px', gap: '48px', padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`, lg: `${content.paddingTop}px 0px ${content.paddingBottom}px 0px`}}}>
                {content.title ? <Typography variant={'h2'} color={content.colorHeading}>
                    {content.title}
                </Typography> : ''}
                <Grid container item flexDirection={'row'} justifyContent={'center'} alignItems={'flex-start'} sx={{gap: '43px'}}>
                    {content.iconGroup.map((item: any, index: number) => {
                        return (
                            <Grid key={index} container item flexDirection={'column'} justifyContent={'center'} alignItems={'center'} sx={{flex: {xs: '0 1 13%', lg: '1 1 13%'}, gap: '8px', textAlign: 'center'}}>
                                <Box component={"img"} src={item.icon.url} alt={item.icon.alt} sx={{width: '100px', height: '100px', maxWidth: {xs: '100px', lg: '100px'}, maxHeight: {xs: '100px', lg: '100px'}, objectFit: 'contain'}} />
                                {item.title ? <Typography variant={'h3'} color={content.colorHeadingIcon} sx={{marginTop: '16px'}}>
                                    {item.title}
                                </Typography> : ''
                                }
                                {item.description ?
                                    <Wysiwyg children={item.description} color={content.colorText} fontSize={'body2'} lineHeight={'18px'} />
                                    : ''
                                }
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default IconList;
