import {Grid, Box, Typography} from "@mui/material";
import DownloadButton from "../ui/DownloadButton";
import Wysiwyg from "./WYSIWYG";
import Button from "../ui/Button";

type Props = {
    content: any;
}

const ListMaterials = ({content}: Props) => {

    return (
        <Grid container component={'section'} justifyContent={'center'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={'center'} alignItems={'center'} flexDirection={'column'} sx={{maxWidth: '1176px', padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`, lg: `${content.paddingTop}px 0px ${content.paddingBottom}px 0px`}}}>
                {content.title ? <Typography variant={'h2'} color={content.colorHeading} sx={{textAlign: {xs: 'center', sm: 'left'}, marginBottom: '24px'}}>
                    {content.title}
                </Typography> : ''}
                {
                    content.materials.map((item: any, index: number) => {
                        return (
                            <Grid key={index} container component={'article'} item justifyContent={{xs: 'flex-end', sm: 'space-between'}} sx={{gap: '24px', borderBottom: '1px solid #8E9EB6', padding: {xs: '12px', sm: '24px'}}}>
                                <Grid item>
                                    {item.title ? <Typography variant={'h3'} color={content.colorHeadingMaterial}>{item.title}</Typography> : ''}
                                    {item.description ?
                                        <Wysiwyg children={item.description} color={content.colorText} />
                                        : ''
                                    }
                                    <Grid container item sx={{gap: '4px'}}>
                                        {
                                            item.labels.map((item: any, index: number) => {
                                                return (
                                                    <Box key={index} sx={{padding: '4px 8px', fontSize: '20px', fontWeight: 600, width: 'min-content', marginTop: '4px', backgroundColor: `${content.colorLabel}`, color: `${content.colorLabelText}`, borderRadius: '4px'}}>
                                                        {item.label}
                                                    </Box>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                                <Grid item sx={{display: 'flex', flexDirection: 'row', gap: '20px', justifyContent: 'flex-end', alignItems: 'center'}}>
                                    {item.file ? <DownloadButton content={item.file} color={content.colorText} /> : ''}
                                    {
                                        item.button.url ? <Button content={item.button} bckColor={content.colorButton} color={content.colorFontButton} /> : ''
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Grid>
        </Grid>
    )
}

export default ListMaterials;
