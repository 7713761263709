interface Color {
    primary: string;
    secondary: string;
    paggination: string;
}

export class ContactCard {
    name: string;
    email: string;
    position: string;
    phoneNumber: string;

    private constructor(name: string, email: string, position: string, phoneNumber: string) {
        this.name = name;
        this.email = email;
        this.position = position;
        this.phoneNumber = phoneNumber;
    }

    public static fromApiResponse(data: any) {
        return new ContactCard(
            data.name,
            data.email,
            data.position,
            data.phone_number
        )
    }
}

export interface Menu {
    title: string;
    links: {link:{title: string, url: string, target: string}}[];
}

class Footer {
    contactCards: ContactCard[];
    menu: Menu[];
    logoFooter: string;

    constructor(contactCards: ContactCard[], menu: Menu[], logoFooter: string) {
        this.contactCards = contactCards;
        this.menu = menu;
        this.logoFooter = logoFooter;
    }
}

class Typography {
    h1: number;
    h2: number;
    h3: number;
    h4: number;
    h5: number;
    h6: number;
    body1: number;
    body2: number;
    button: number;

    constructor(h1: string, h2: string, h3: string, h4: string, h5: string, h6: string, body1: string, body2: string, button: string) {
        this.h1 = parseInt(h1);
        this.h2 = parseInt(h2);
        this.h3 = parseInt(h3);
        this.h4 = parseInt(h4);
        this.h5 = parseInt(h5);
        this.h6 = parseInt(h6);
        this.body1 = parseInt(body1);
        this.body2 = parseInt(body2);
        this.button = parseInt(button);
    }
}

class Settings {
    logo: string;
    logoUrl: string;
    colors: Color;
    typography: Typography;
    footer: Footer

    constructor(logo: string, logoUrl: string, colors: Color, typography: Typography, footer: Footer) {
        this.logo = logo;
        this.logoUrl = logoUrl;
        this.colors = colors;
        this.typography = typography;
        this.footer = footer;
    }

    public static fromApiResponse(data: any) {
        const {acf} = data.find((d: any) => d.post_parent === 0);
        const {h1, h2, h3, h4, h5, h6, body1, body2, button} = acf.typography
        return new Settings(
            acf.logo,
            acf.logo_url,
            acf.colors,
            new Typography(h1, h2, h3, h4, h5, h6, body1, body2, button),
            new Footer(acf.footer.contact_cards ? acf.footer.contact_cards.map((c: any) => ContactCard.fromApiResponse(c)) : [], acf.footer.menu, acf.footer.logo)
        )
    }

}

export default Settings;
