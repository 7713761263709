import axios from "axios";

export const getRequest =  async (path: string, onError?: (error: any) => void) => {
    const url = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL + path : "";
    let data = {} as any;
    const config = {
        headers: {
            // "Accept-Content": "application/json" FOR LARAVEL ONLY
        }
    }
    try {
        const response = await axios.get(url, config)
        if (response.status == 200) {
            data = response.data;
        } else {
            onError && onError(response);
        }
    } catch(error: any) {
        console.error(error.response? error.response : error);
        onError && onError(error.response ? error.response : "Błąd serwera!");
    }
    return data;
}
