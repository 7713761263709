import {Grid, Box, Typography} from "@mui/material";
import Wysiwyg from "./WYSIWYG";
import Button from "../ui/Button";
import React from "react";

type Props = {
    content: any;
}
const TextWithImage = ({content}: Props) => {
    let marginSection = content.scheme === 'left' ? '0 40px 0 0' : '0 0 0 40px'

    return (
        <Grid component={'section'} container justifyContent={content.scheme === 'left' ? 'flex-start' : 'flex-end'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={{xs: 'center', lg: 'flex-start'}} alignItems={'center'} flexDirection={{xs: 'column', lg: content.scheme === 'left' ? 'row' : 'row-reverse'}} sx={{flexWrap: {xs: 'wrap', lg: 'nowrap'}, maxWidth: '1548px', gap: {xs: '40px', lg: '80px', xl: '126px'}, padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`, lg: `${content.paddingTop}px 0px`}, margin: {xs: 0, lg: marginSection}}}>
                <Grid item sx={{textAlign: content.scheme === ' left' ? 'left' : 'right'}}>
                    <Box component={"img"} src={content.img.url} alt={content.img.alt} sx={{maxWidth: '883px', maxHeight: '588px', width: '100%', height: '100%', objectFit: 'cover'}} />
                </Grid>
                <Grid container item sx={{maxWidth: {xs: '100%', lg: '576px'}, flexDirection: 'column', gap: '24px'}} justifyContent={'flex-start'}>
                    {content.title ? <Typography variant={'h2'} color={content.colorHeading}>
                        {content.title}
                    </Typography> : ''}
                    {content.description ?
                        <Wysiwyg children={content.description} color={content.colorText} />
                        : ''
                    }
                    {
                        content.button ? <Grid item sx={{marginTop: '20px', marginBottom: '20px'}}><Button content={content.button} bckColor={content.colorButton} color={content.colorFontButton} /></Grid> : ''
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default TextWithImage;
