const FacebookIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.7657 0H3.23405C1.44797 0 0 1.44791 0 3.23405V20.7658C0 22.552 1.44791 23.9999 3.23405 23.9999H11.8806L11.8953 15.4236H9.66721C9.37765 15.4236 9.14264 15.1895 9.14152 14.8999L9.13084 12.1354C9.12972 11.8443 9.36544 11.6077 9.65659 11.6077H11.8806V8.93651C11.8806 5.83661 13.7739 4.14869 16.5392 4.14869H18.8083C19.0986 4.14869 19.3341 4.38406 19.3341 4.67444V7.00547C19.3341 7.29573 19.0988 7.53104 18.8086 7.53122L17.4161 7.53187C15.9122 7.53187 15.621 8.24648 15.621 9.29522V11.6078H18.9255C19.2404 11.6078 19.4847 11.8827 19.4476 12.1954L19.1199 14.9599C19.0885 15.2244 18.8642 15.4237 18.5978 15.4237H15.6357L15.621 24H20.7658C22.5519 24 23.9998 22.5521 23.9998 20.766V3.23405C23.9998 1.44791 22.5519 0 20.7657 0Z"
                fill="black"/>
        </svg>

    )
}

export default FacebookIcon
