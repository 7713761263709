import {Box, Grid} from "@mui/material";
import Wysiwyg from "./WYSIWYG";
import Button from "../ui/Button";
import React from "react";

type Props = {
    content: any;
}

const FilmSection = ({content}: Props) => {

    return (
        <Grid component={'section'} container justifyContent={'center'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={'center'} alignItems={'center'} flexDirection={'row'} sx={{maxWidth: '1176px', padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`, lg: `${content.paddingTop}px 0px ${content.paddingBottom}px 0px`}}}>
                <Grid item>
                    {content.description ?
                        <Wysiwyg children={content.description} color={content.colorText} />
                        : ''
                    }
                </Grid>
                <Grid item xs={12} sx={{textAlign: 'center'}} mt={content.description ? '20px' : 0} mb={content.button ? '20px' : 0}>
                    {content.film ?
                        <Box sx={{borderRadius: '20px', width: {xs: '100%', md: '900px', lg: '942px'}, height: {xs: '390px', sm: '530px'}}} component={'iframe'} src={content.film} title="Cyfrowy Dialog" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></Box>
                    : ''}
                </Grid>
                <Grid item xs={12}>
                    {
                        content.button ? <Grid item xs={12} sx={{marginTop: '40px', marginBottom: '20px', textAlign: 'center'}}><Button content={content.button} bckColor={content.colorButton} color={content.colorFontButton} /></Grid> : ''
                    }
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FilmSection;
