import {useContext} from "react";
import ContentContext from "../store/content-context";
import getComponents from "./getComponents";
import Page from "../model/Page";
import MainLayout from "../layout/MainLayout";

type Props = {
    pageId: string | number;
}
const Preview = ({pageId}: Props) => {
    const {pages} = useContext(ContentContext);
    const currentPage = pages.find((p: Page) => p.id == pageId);
    const components = currentPage ? getComponents((currentPage as Page).blocks) : [];

    return <MainLayout pageTitle={currentPage ? (currentPage as Page).title : ''}>{components}</MainLayout>
}

export default Preview;