import {Box, Grid, styled, Typography} from "@mui/material";
import React, {useContext} from "react";
import {Autoplay, Controller, Navigation, Pagination} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import ArrowLeft from '../ui/icons/ArrowLeft';
import Wysiwyg from './WYSIWYG';
import ContentContext from "../store/content-context";
import {newShade} from "../utils/image-tools";
import Button from "../ui/Button";
import "./Slider.scss";

type Props = {
    content: any;
}


const Slider = ({content}: Props) => {
    const {contrast} = useContext(ContentContext);

    const divStyle = {
        color: content.optionSlider.colorArrows,
        margin: '0',
        width: '100%',
        '--color': content.optionSlider.colorPagginationSlider
    };

    return (
        <Grid container component={'section'} sx={{position: 'relative', minHeight: '650px'}} justifyContent={'center'}>
            <Grid item sx={{position: 'relative', maxWidth: '1176px', width: '100%'}}>
                {content.link ?
                    <Box component={"a"} href={content.link.url} target={content.link.target} sx={{textDecoration: 'none', zIndex: 3, margin: {xs: '15px 20px 0 20px', sm: '40px 40px 0 40px', lg: '40px 0 0 0'}, position: 'absolute', top: '0%', left: '0', display: 'flex', alignItems: 'center', gap: '12px'}}>
                        <ArrowLeft color={content.colorLink} />
                        <Typography variant="button" color={content.colorLink}>{content.link.title}</Typography>
                    </Box> : ''
                }
            </Grid>
                <Swiper className="swiper" style={divStyle} centeredSlides={true} loop={content.slide.length > 1 ? false : false} navigation={content.slide.length > 1} pagination={content.slide.length > 1} autoplay={{delay: content.optionSlider.timeSlide, disableOnInteraction: false}} slidesPerView={1} modules={[Controller, Pagination, Navigation]}>
                    {content.slide.map((item: any, index: number) => {
                        return (
                            <SwiperSlide key={index}>
                                <Grid container item sx={{position: 'relative', backgroundColor: newShade(item.slide.backgroundColor, contrast ? -100 : 0), width: '100%', height: '100%'}}>
                                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '1176px', padding: {xs: '0 40px', sm: '0 40px'}, margin: '0 auto'}}>
                                        {
                                            item.slide.image ? <Box component={'img'} src={item.slide.image.url} alt={item.slide.image.alt} sx={{position: 'absolute', top: '0', left: '0', width: '100%', zIndex: 1, maxHeight: {xs: '100%', sm: '650px'}, height: '100%', objectFit: 'cover', filter: contrast ? "brightness(50%)" : ""}} /> : ''
                                        }
                                        <Grid container item flexDirection={{xs: 'column', sm: 'row'}} lg={12} sx={{marginTop: {xs: '100px', sm: '40px'}, position: 'relative', zIndex: 3, gap: {xs: '30px', sm: '40px'}, minHeight: '650px', paddingBottom: {xs: '40px', md: 0}}} justifyContent={{xs: 'center', sm: 'space-between'}} alignItems={'center'}>
                                            <Grid item xs={12} sm={7} lg={6.5} sx={{display: 'flex', flexDirection: 'column', gap: {xs: '8px', sm: '16px'}}}>
                                                <Typography variant="body1" color={contrast ? "white" : item.slide.colorOfFontsAdditional} sx={{fontWeight: 600}}>{item.slide.label ? item.slide.label : ''}</Typography>
                                                <Typography variant="h1" color={contrast ? "white" : item.slide.colorOfFonts}>{item.slide.title ? item.slide.title : ''}</Typography>
                                                <Typography variant="h3" color={contrast ? "white" : item.slide.colorOfFonts}>{item.slide.subtitle ? item.slide.subtitle : ''}</Typography>
                                                {item.slide.description ?
                                                    <Wysiwyg children={item.slide.description} color={contrast ? "white" : item.slide.colorOfFonts} />
                                                    : ''
                                                }
                                                {
                                                    item.slide.button ? <Grid item sx={{marginTop: {xs: '24px', sm: '24px'}, marginBottom: {xs: '8px', sm: 0}, textAlign: {xs: 'center', sm: 'left'}}}><Button content={item.slide.button} bckColor={item.slide.colorButton} color={item.slide.colorFontButton} /></Grid> : ''
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={4} lg={5} sx={{display: 'flex', justifyContent: {xs: 'center', sm: 'flex-end'}}}>
                                                {item.slide.picture.url ?
                                                    <Box component={"img"} src={item.slide.picture.url} alt={item.slide.picture.alt} sx={{maxWidth: {xs: '60%', sm: '100%', lg: '475px'}, maxHeight: '374px', objectFit: 'cover' }} />
                                                    : ''
                                                }
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box sx={{position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', zIndex: 2, backgroundColor: item.slide.colorOverlay ? item.slide.colorOverlay : '', opacity: item.slide.opacity ? item.slide.opacity : ''}} />
                                </Grid>
                            </SwiperSlide>
                        )
                    })}
                </Swiper>
        </Grid>
    )
}

export default Slider;
