import {Box, Typography, useTheme} from "@mui/material";
import DownloadIcon from "./icons/DownloadIcon";

type Props = {
    content: any;
    color?: any;
}

const DownloadButton = ({content, color}: Props) => {
    const {palette} = useTheme();

    return (
        <Box component={'a'} href={content.url} target={'_blank'} download sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', textDecoration: 'none', color: color ? color : palette.primary.main, '&:hover': {cursor: 'pointer'}}}>
            <DownloadIcon />
            <Typography sx={{fontSize: '20px', fontWegiht: 600}} color={color ? color : palette.primary.main}>pobierz</Typography>
        </Box>
    )
}

export default DownloadButton;
