import {ReactNode, useEffect} from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

type Props = {
    children: ReactNode;
    pageTitle: string;
}

const MainLayout = ({children, pageTitle}: Props) => {

    useEffect(() => {
        document.title = 'CyfrowyDialog - ' + pageTitle;
    }, [])

    return (
        <>
            <Header/>
            {children}
            <Footer/>
        </>
    );
}

export default MainLayout;
