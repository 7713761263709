import {ContactCard, Menu} from "./Settings";

interface SocialLinks {
    facebook: string;
    twitter: string;
    youtube: string;
    instagram: string;
    linkedin: string;
}

interface Doc {
    title: string;
    url: string;
}

class Docs {
    accessibility: Doc;
    cookies: Doc;
    privacyPolicy: Doc;

    private constructor(accessibility: Doc, cookies: Doc, privacyPolicy: Doc) {
        this.accessibility = accessibility;
        this.cookies = cookies;
        this.privacyPolicy = privacyPolicy;
    }

    public static fromApiResponse(data: any) {
        return new Docs(
            data.accessibility,
            data.cookies,
            data.privacy_policy
        )
    }
}

class Contact {
    address: string;
    contactCards: ContactCard[];

    private constructor(address: string, contactCards: ContactCard[]) {
        this.address = address;
        this.contactCards = contactCards;
    }

    public static fromApiResponse(data: any) {
        return new Contact(
            data.address,
            data.contact_cards ? data.contact_cards.map((c: any) => ContactCard.fromApiResponse(c)) : []
        )
    }
}

class Options {
    contact: Contact;
    contactEmail: string;
    docs: Docs;
    socialLinks: SocialLinks;
    menu: Menu[];

    private constructor(contact: Contact, contactEmail: string, docs: Docs, socialLinks: SocialLinks, menu: Menu[]) {
        this.contact = contact;
        this.contactEmail = contactEmail;
        this.docs = docs;
        this.socialLinks = socialLinks;
        this.menu = menu;
    }

    public static fromApiResponse({acf}: any) {
        return new Options(
            Contact.fromApiResponse(acf.contact),
            acf.contact_email,
            Docs.fromApiResponse(acf.docs),
            acf.social_media,
            acf.nav,
        )
    }
}

export default Options;