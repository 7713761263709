import {Grid, Typography, Divider} from "@mui/material";
import Wysiwyg from "./WYSIWYG";

type Props = {
    content: any;
}

const Timeline = ({content}: Props) => {

    return (
        <Grid component={'section'} container justifyContent={'center'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={'center'} alignItems={'center'} flexDirection={'column'} sx={{flexWrap: 'nowrap', gap: '80px', padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`, lg: `${content.paddingTop}px 0px ${content.paddingBottom}px 0px`}}}>
                {content.title ? <Typography variant={'h2'} color={content.colorHeading} sx={{textAlign: {xs: 'center', sm: 'left'}}}>
                    {content.title}
                </Typography> : ''}
                <Grid container item xs={12} md={content.events.length > 3 ? 11 : 7} flexDirection={{xs: 'column', sm: 'row', md: 'column'}} sx={{position: {xs: 'static', sm: 'relative', md: 'static'}, gap: {xs: '0px', sm: '18px'}, flexWrap: 'nowrap'}}>
                    <Grid container item flexDirection={{xs: 'column', md: 'row'}} justifyContent={'space-around'} alignItems={'flex-end'} sx={{display: {xs: 'none', sm: 'flex'}, flexWrap: 'nowrap'}}>
                    {
                        content.events.map((item: any, index: number) => {
                            return (index % 2 === 0 ?
                                <Grid key={index} container item xs={12} md={3.5} flexDirection={{xs: 'column', sm: 'row'}}
                                      justifyContent={{xs: 'center', sm: 'center'}}
                                      alignItems={'center'}
                                      sx={{textAlign: 'center', gap: {xs: '20px', sm: 0}, '&:not(:first-child)': {marginTop: {xs: '40px', sm: 0}}}}>
                                    <Grid item sx={{
                                        display: {xs: 'block', sm: 'none'},
                                        width: '66px',
                                        height: '66px',
                                        fontSize: '27px',
                                        fontWeight: 600,
                                        lineHeight: '60px',
                                        textAlign: 'center',
                                        borderRadius: '50%',
                                        zIndex: '2',
                                        backgroundColor: `${content.colorCircle}`,
                                        border: `1px solid ${content.colorTimeline}`,
                                        color: `${content.colorNumber}`
                                    }}>
                                        0{index + 1}.
                                    </Grid>
                                    {item.event ?
                                        <Wysiwyg children={item.event} color={content.colorText} />
                                        : ''
                                    }
                                    <Divider sx={{
                                        display: {xs: 'block', sm: 'none'},
                                        width: '100%',
                                        backgroundColor: `${content.colorTimeline}`
                                    }}/>
                                </Grid>
                                :
                                <Grid key={index+3} container item xs={12} md={3.5} justifyContent={{xs: 'center', sm: 'flex-start'}} sx={{textAlign: 'center', gap: {xs: '20px', sm: 0}}}>
                                </Grid>
                            )
                        })}
                    </Grid>

                    <Grid container xs={4} md={12} item flexDirection={{sm: 'column', md: 'row'}} justifyContent={'space-around'} alignItems={'center'} sx={{display: {xs: 'none', sm: 'flex'}, flexWrap: 'nowrap', gap: {sm: '40px', md: 0}, '&::after': {position: 'absolute', content: '""', width: {sm: '.5px', md: '100%'}, backgroundColor: content.colorTimeline, height: {sm: '120%', md: '.5px'}, zIndex: '1'}}}>
                        {
                            content.events.map((item: any, index: number) => {
                                return (
                                    <Grid key={index} item sx={{width: '66px', height: '66px', fontSize: '27px', fontWeight: 600, lineHeight: '60px', textAlign: 'center', borderRadius: '50%', zIndex: '2',
                                        backgroundColor: `${content.colorCircle}`,
                                        border: `1px solid ${content.colorTimeline}`,
                                        color: `${content.colorNumber}`
                                    }}>
                                        0{index+1}.
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                    <Grid container item flexDirection={{xs: 'column', md: 'row'}} justifyContent={'space-around'} alignItems={'flex-start'} sx={{display: {xs: 'none', sm: 'flex'}, flexWrap: 'nowrap'}}>
                        {
                            content.events.map((item: any, index: number) => {
                                return (index % 2 !== 0 ?
                                    <Grid key={index} container item xs={12} md={3.5} flexDirection={{xs: 'column', sm: 'row'}} justifyContent={{xs: 'center', sm: 'center'}} alignItems={'center'} sx={{marginTop: {xs: '40px', sm: 0}, textAlign: 'center', gap: {xs: '20px', sm: 0}}}>
                                        <Grid item sx={{display: {xs: 'block', sm: 'none'}, width: '66px', height: '66px', fontSize: '27px', fontWeight: 600, lineHeight: '60px', textAlign: 'center', borderRadius: '50%', zIndex: '2',
                                            backgroundColor: `${content.colorCircle}`,
                                            border: `1px solid ${content.colorTimeline}`,
                                            color: `${content.colorNumber}`
                                        }}>
                                            0{index+1}.
                                        </Grid>
                                        {item.event ?
                                            <Wysiwyg children={item.event} color={content.colorText} />
                                            : ''
                                        }
                                        <Divider sx={{display: {xs: 'block', sm: 'none'}, width: '100%', backgroundColor: `${content.colorTimeline}`}} />
                                    </Grid>
                                    :
                                    <Grid key={index+3} container item xs={12} md={3.5} justifyContent={{xs: 'center', sm: 'flex-start'}} sx={{textAlign: 'center', gap: {xs: '20px', sm: 0}}}>
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                    <Grid container item flexDirection={{xs: 'column', md: 'row'}} justifyContent={'space-around'} alignItems={'flex-start'} sx={{display: {xs: 'flex', sm: 'none'}, flexWrap: 'nowrap'}}>
                        {
                            content.events.map((item: any, index: number) => {
                                return (
                                    <Grid key={index} container item xs={12} md={3.5} flexDirection={{xs: 'column', sm: 'row'}} justifyContent={{xs: 'center', sm: 'center'}} alignItems={'center'} sx={{display: {xs: 'flex', sm: 'none'}, marginTop: {xs: '40px', sm: 0}, textAlign: 'center', gap: {xs: '20px', sm: 0}}}>
                                        <Grid item sx={{display: {xs: 'block', sm: 'none'}, width: '66px', height: '66px', fontSize: '27px', fontWeight: 600, lineHeight: '60px', textAlign: 'center', borderRadius: '50%', zIndex: '2',
                                            backgroundColor: `${content.colorCircle}`,
                                            border: `1px solid ${content.colorTimeline}`,
                                            color: `${content.colorNumber}`
                                        }}>
                                            0{index+1}.
                                        </Grid>
                                        {item.event ?
                                            <Wysiwyg children={item.event} color={content.colorText} />
                                            : ''
                                        }
                                        <Divider sx={{display: {xs: 'block', sm: 'none'}, width: '100%', backgroundColor: `${content.colorTimeline}`}} />
                                    </Grid>
                                )
                            })
                        }
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
    )
}

export default Timeline;
