import Block from "../model/Block";
import Slider from '../components/Slider';
import TextBox from "../components/TextBox";
import TextWithImage from "../components/TextWithImage";
import TextWithIcon from "../components/TextWithIcon";
import Gallery from "../components/Gallery";
import ImageSection from "../components/ImageSection";
import IconList from "../components/IconList";
import Timeline from "../components/Timeline";
import ListMaterialsWithImage from "../components/ListMaterialsWithImage";
import ListMaterials from "../components/ListMaterials";
import FilmSection from "../components/FilmSection";

const getComponents = (blocks: Block[]) => {
    return blocks.map((block, index) => {
        switch (block.component) {
            case "slider": return <Slider key={index} content={block.content} />
            case "textBox": return <TextBox key={index} content={block.content} />
            case "textWithImage": return <TextWithImage key={index} content={block.content} />
            case "textWithIcon": return <TextWithIcon key={index} content={block.content} />
            case "gallery": return <Gallery key={index} content={block.content} />
            case "imageSection": return <ImageSection key={index} content={block.content} />
            case "iconList": return <IconList key={index} content={block.content} />
            case "timeline": return <Timeline key={index} content={block.content} />
            case "listMaterialsWithImage": return <ListMaterialsWithImage key={index} content={block.content} />
            case "listMaterials": return <ListMaterials key={index} content={block.content} />
            case "filmSection": return <FilmSection key={index} content={block.content} />
            default: return <></>
        }
    })
}

export default getComponents;
