import {Grid, Box} from "@mui/material";

type Props = {
    content: any;
}

const ImageSection = ({content}: Props) => {

    return (
        <Grid component={'section'} container justifyContent={'center'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={'center'} alignItems={'center'} flexDirection={'column'} sx={{maxWidth: '1176px', padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`, lg: `${content.paddingTop}px 0px ${content.paddingBottom}px 0px`}}}>
                <Box component={"img"} src={content.img.url} alt={content.img.url} sx={{width: '100%', height: '100%', maxWidth: {xs: '883px', lg: '1176px'}, maxHeight: {xs: '588px', lg: '548px'}, objectFit: 'cover'}} />
            </Grid>
        </Grid>
    )
}

export default ImageSection;
