import {Grid, Box, Typography} from "@mui/material";
import Wysiwyg from "./WYSIWYG";
import Button from "../ui/Button";

type Props = {
    content: any;
}

const TextWithIcon = ({content}: Props) => {

    return (
        <Grid component={'section'} container justifyContent={'center'} sx={{backgroundColor: content.backgroundColor, padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`}}}>
            <Grid container item justifyContent={{xs: 'center', lg: 'space-between'}} alignItems={'center'} flexDirection={{xs: 'column', lg: 'row'}} sx={{maxWidth: content.icon.url ? '1176px' : '776px', flexWrap: 'nowrap', gap: {xs: '53px', lg: '53px', xl: '53px'}}}>
                <Grid container xs={12} lg={content.icon.url ? 8 : 12} item sx={{order: {xs: 2, lg: 1}, flexDirection: 'column', gap: '24px', textAlign: content.icon.url ? 'left' : 'center'}} justifyContent={'flex-start'} alignItems={content.icon.url ? '' : 'center'}>
                    {content.title ? <Typography variant={'h2'} color={content.colorHeading}>
                        {content.title}
                    </Typography> : ''}
                    {content.description ?
                        <Wysiwyg children={content.description} color={content.colorText} />
                        : ''
                    }
                    {
                        content.button ? <Grid item sx={{marginTop: '20px'}}><Button content={content.button} bckColor={content.colorButton} color={content.colorFontButton} /></Grid> : ''
                    }
                </Grid>
                {content.icon.url ?
                    <Grid item xs={12} lg={3} sx={{display: 'flex', order: {xs: 1, lg: 2}}}>
                        <Box component={"img"} src={content.icon.url} alt={content.icon.alt} sx={{maxWidth: '271px', maxHeight: '241px', width: '100%', height: '100%', objectFit: 'cover'}} />
                    </Grid> : ''
                }
            </Grid>
        </Grid>
    )
}

export default TextWithIcon;
