const InstagramIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path
                    d="M12.0003 0.00661609C7.08512 0.00661609 2.41766 -0.430274 0.634874 4.14152C-0.101523 6.02975 0.00541729 8.48201 0.00541729 11.9934C0.00541729 15.0746 -0.0934008 17.9705 0.634874 19.8439C2.4136 24.4183 7.11896 23.9801 11.9976 23.9801C16.7043 23.9801 21.5572 24.4697 23.3617 19.8439C24.0994 17.9367 23.9911 15.5209 23.9911 11.9934C23.9911 7.31066 24.2497 4.2876 21.9769 2.01793C19.6756 -0.281488 16.5635 0.00661609 11.9949 0.00661609H12.0003ZM10.9255 2.16672C21.1782 2.15049 22.4831 1.0116 21.763 16.833C21.5071 22.4287 17.2431 21.8146 12.0016 21.8146C2.44473 21.8146 2.16994 21.5414 2.16994 11.9879C2.16994 2.32362 2.92799 2.17213 10.9255 2.16401V2.16672ZM18.4032 4.15639C17.6086 4.15639 16.9642 4.80023 16.9642 5.59421C16.9642 6.38818 17.6086 7.03202 18.4032 7.03202C19.1978 7.03202 19.8421 6.38818 19.8421 5.59421C19.8421 4.80023 19.1978 4.15639 18.4032 4.15639ZM12.0003 5.83768C8.59852 5.83768 5.84109 8.59428 5.84109 11.9934C5.84109 15.3924 8.59852 18.1477 12.0003 18.1477C15.4021 18.1477 18.1581 15.3924 18.1581 11.9934C18.1581 8.59428 15.4021 5.83768 12.0003 5.83768ZM12.0003 7.99778C17.2864 7.99778 17.2932 15.9889 12.0003 15.9889C6.71557 15.9889 6.70744 7.99778 12.0003 7.99778Z"
                    fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>


    )
}

export default InstagramIcon
