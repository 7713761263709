import {Grid, Box, Typography} from "@mui/material";
import Button from "../ui/Button";

type Props = {
    content: any;
}
const Gallery = ({content}: Props) => {

    return (
        <Grid component={'section'} container justifyContent={'center'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={{xs: 'center', lg: 'flex-start'}} alignItems={'center'} flexDirection={'column'} sx={{flexWrap: {xs: 'wrap', lg: 'nowrap'}, maxWidth: '1548px', gap: '40px', padding: {xs: '80px 20px', sm: '80px 40px', lg: '80px 0'}}}>
                {content.title ? <Typography variant={'h2'} color={content.colorHeading}>
                    {content.title}
                </Typography> : ''}
                <Grid container item sx={{gap: '23px', maxWidth: '1177px', flexWrap: {xs: 'nowrap', lg: 'wrap'}}} justifyContent={'center'} alignItems={'center'} flexDirection={{xs: 'column', lg: 'row'}}>
                    {content.img.map((item: any, index: number) => {
                        if(index >= 3){
                            return (
                                <Box key={index} component={"img"} src={item.url} alt={item.alt} sx={{maxWidth: {xs: '883px', lg: '576px'}, maxHeight: {xs: '260px', sm: '388px', lg: '260px'}, width: '100%', height: '100%', objectFit: 'cover'}} />
                            )
                        } else {
                            return (
                                <Box key={index} component={"img"} src={item.url} alt={item.alt} sx={{maxWidth: {xs: '883px', lg: '377px'}, maxHeight: {xs: '260px', sm: '388px', lg: '260px'}, width: '100%', height: '100%', objectFit: 'cover'}} />
                            )
                        }
                    })}
                </Grid>
                {
                    content.button ? <Button content={content.button} bckColor={content.colorButton} color={content.colorFontButton} /> : ''
                }
            </Grid>
        </Grid>
    )
}

export default Gallery;
