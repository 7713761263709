import {Grid, Box, Pagination, Typography, styled} from "@mui/material";
import {useState} from "react";
import Wysiwyg from "./WYSIWYG";
import Button from "../ui/Button";
import DownloadButton from "../ui/DownloadButton";

const PaginationButton = styled(Pagination)(({theme}) => ({
    '& .MuiPagination-ul': {
        gap: '10px',
    },
    '& .MuiPaginationItem-page': {
        backgroundColor: '#fff',
        color: 'black',
        minHeight: '32px',
        minWidth: '32px',
        borderRadius: '100%',
        fontSize: '20px',
        fontWeight: 600,
        [theme.breakpoints.down('sm')]: {
            minHeight: '26px',
            minWidth: '26px',
            fontSize: '16px',
        },

        '&.Mui-selected': {
            backgroundColor: theme.palette.primary.light,
            color: '#fff'
        },
    },

    '& .MuiPaginationItem-previousNext': {
        minHeight: '32px',
        minWidth: '32px',
        borderRadius: '100%',
        fontSize: '20px',
        backgroundColor: 'transparent !important',
        color: theme.palette.secondary.main,

        [theme.breakpoints.down('md')]: {
            minHeight: '26px',
            minWidth: '26px',
        },

        '&.Mui-disabled': {
            opacity: 0,
        },

        '& svg': {
            fontSize: '45px',
            color: theme.palette.primary.light,

            [theme.breakpoints.down('md')]: {
                fontSize: '40px'
            },
        }
    }
}))

type Props = {
    content: any;
}

const ListMaterialsWithImage = ({content}: Props) => {
    const [materialsIndex, setMaterialsIndex] = useState(content.materialsPerPage);
    const [prevIndex, setPrevIndex] = useState(0);
    const [page, setPage] = useState(1);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
        setMaterialsIndex(value * content.materialsPerPage)
        if(value === 1) {
            setPrevIndex(0)
        } else {
            setPrevIndex((content.materialsPerPage * value) - content.materialsPerPage)
        }
    };

    return (
        <Grid component={'section'} container justifyContent={'center'} sx={{backgroundColor: content.backgroundColor}}>
            <Grid container item justifyContent={'center'} alignItems={'center'} flexDirection={'column'} sx={{gap: '48px', maxWidth: '1176px', padding: {xs: `${content.paddingTop}px 20px ${content.paddingBottom}px 20px`, sm: `${content.paddingTop}px 40px ${content.paddingBottom}px 40px`, lg: `${content.paddingTop}px 0px ${content.paddingBottom}px 0px`}}}>

                {content.title ? <Typography variant={'h2'} color={content.colorHeading} sx={{textAlign: {xs: 'center', sm: 'left'}}}>
                    {content.title}
                </Typography> : ''}

                {
                    content.materials.slice(prevIndex, materialsIndex).map((item: any, index: number) => {
                        return (
                            <Grid key={index} container item flexDirection={{xs: 'column', md: 'row'}} component={'article'} sx={{flexWrap: 'nowrap', backgroundColor: '#fff'}}>
                                <Box component={"img"} src={item.img.url} alt={item.img.alt} sx={{maxWidth: {xs: '100%', md: '276px'}, width: '100%', height: {xs: '100%', sm: 'auto', md: 'auto'}, objectFit: 'cover', alignSelf: 'flex-start'}} />
                                <Grid container item justifyContent={'space-between'} flexDirection={'column'} sx={{flexWrap: 'nowrap', gap: '24px', padding: '24px', flexBasis: {xs: '100%', md: '76.5%'}, maxWidth: {xs: '100%', md: '76.5%'}}}>
                                    <Grid container item flexDirection={'column'} sx={{gap: '16px'}}>
                                        {item.title ? <Typography variant={"h3"} color={content.colorHeadingMaterial}>{item.title}</Typography> : ''}
                                        {item.description ?
                                            <Wysiwyg children={item.description} color={content.colorText} />
                                            : ''
                                        }
                                        {item.authors ? <Typography variant={"body1"} color={content.colorText}>{item.authors}</Typography>: ''}
                                    </Grid>
                                    <Grid container item flexDirection={{xs: 'column', sm: 'row'}} justifyContent={{xs: 'center', sm: 'space-between'}} sx={{gap: '24px'}}>
                                        <Grid item sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center', alignItems: 'flex-end'}}>
                                            {item.file.url ?
                                                <DownloadButton content={item.file} color={content.colorText} />
                                                : ''
                                            }
                                        </Grid>
                                        <Grid item sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '8px', justifyContent: 'center', alignItems: 'flex-end'}}>
                                            {
                                                    item.button.url ? <Button content={item.button} bckColor={content.colorButton} color={content.colorFontButton} /> : ''
                                            }
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })
                }

                {
                    content.materials.length > content.materialsPerPage ?
                        <Grid container item justifyContent={'flex-end'} alignItems={'center'} sx={{gap: '16px'}}>
                            <PaginationButton count={Math.ceil(content.materials.length / content.materialsPerPage)} page={page} onChange={handleChange} />
                        </Grid>
                        : ''
                }

            </Grid>
        </Grid>
    )
}

export default ListMaterialsWithImage;
