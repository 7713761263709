type Props = {
    onClick: () => void;
}
const EyeIcon = ({onClick}: Props) => {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick}>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M24 12C24 14 18 20 12 20C6 20 0 14 0 12C0 10 6 4 12 4C18 4 24 10 24 12ZM16 12C16 14.2091 14.2091 16 12 16C9.79086 16 8 14.2091 8 12C8 9.79086 9.79086 8 12 8C14.2091 8 16 9.79086 16 12ZM12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
              fill="black"/>
    </svg>
}

export default EyeIcon;
