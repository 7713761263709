const DownloadIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2517_7346)">
                <path fillRule="evenodd" clipRule="evenodd" d="M10.9 3C10.9 2.44771 11.3477 2 11.9 2H12.1C12.6523 2 13.1 2.44772 13.1 3V10.75H16.6827C17.0845 10.75 17.3222 11.1999 17.0957 11.5318L12.413 18.3947C12.2145 18.6856 11.7855 18.6856 11.587 18.3947L6.90428 11.5318C6.67783 11.1999 6.91551 10.75 7.3173 10.75H10.9V3ZM2 20C1.44772 20 1 20.4477 1 21C1 21.5523 1.44772 22 2 22H22C22.5523 22 23 21.5523 23 21C23 20.4477 22.5523 20 22 20H2Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_2517_7346">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default DownloadIcon
