const PhoneIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0)">
                <path fillRule="evenodd" clipRule="evenodd" d="M14.119 15.1078C13.2825 15.5218 12.5831 16.2045 12.1579 17.0768C9.76287 15.2591 8.19013 12.535 7.81346 9.55199C8.99429 9.63483 10.1349 9.22322 10.9862 8.46096L10.9862 2.04823C10.4981 1.60972 9.90205 1.27655 9.22483 1.09509C7.21235 0.555852 5.15074 1.53515 4.25656 3.33061L4.19606 3.3144C4.10799 3.58333 4.0267 3.85616 3.95244 4.13276L3.95043 4.14027L3.94841 4.14778C1.73829 12.4123 6.64052 20.905 14.9018 23.1251L14.9131 23.1281L14.9131 23.1281C17.2105 23.7437 19.5719 22.3803 20.1875 20.0829C20.3124 19.6168 20.3558 19.148 20.3265 18.6917L14.119 15.1078Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

export default PhoneIcon
