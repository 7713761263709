import {useCallback, useContext, useEffect, useState} from "react";
import ContentContext from "../store/content-context";
import {Box, styled, useMediaQuery, useTheme} from "@mui/material";
// @ts-ignore
import GB from "../assets/GB.svg";
import "./Header.scss";
import EyeIcon from "../ui/icons/EyeIcon";
import {NavLink, useLocation} from "react-router-dom";
import {MenuItem} from "../model/Menu";

const Logo = styled("img")(({theme}) => ({
    width: "auto",
    transition: "0.5s ease",
}))

const HeaderBox = styled(Box)(({theme}) => ({
    position: theme.breakpoints.up("md") ? "sticky" : "fixed",
    top: 0,
    left: 0,
    right: 0,
    paddingTop: theme.breakpoints.up("md") ? 0 : "15px",
    paddingBottom: theme.breakpoints.up("md") ? 0 : "15px",
    zIndex: 99,
    boxShadow: "inset 0 -1px 0 0 $gray-100",
    transition: "0.5s ease",
}))

const AccessibilityMenu = () => {
    const {zoom, zoomIn, zoomOut, zoomReset, settings: {typography}, switchContrast} = useContext(ContentContext);
    const buttonStyle = {fontSize: (typography.h4 == 0 ? 17 : typography.h4) + "px"}
    const valueStyle = {fontSize: (typography.h6 == 0 ? 13 : typography.h6) + "px"}

    return <div className="options-menu">
        <button type="button" name="switch contrast" className="contrast">
            <EyeIcon onClick={switchContrast}/>
        </button>
        <div className="resize">
            <button type="button" name="decrease zoom" className="resize-button decrease" style={buttonStyle}
                    onClick={zoomOut}>Aa-
            </button>
            <span className="resize-value" title="Kliknij by zresetować" onClick={zoomReset}
                  style={valueStyle}>{zoom * 100}<span>%</span></span>
            <button type="button" name="increase zoom" className="resize-button increase" style={buttonStyle}
                    onClick={zoomIn}>Aa+
            </button>
        </div>
        <div className="lang" title="Dostępny wkrótce / available soon">
            <img src={GB} alt="GB"/>
        </div>
    </div>
}

const Menu = () => {
    const {menu: {menuItems}, settings: {typography}} = useContext(ContentContext);
    const [activeIndex, setActiveIndex] = useState(-1);
    const location = useLocation();
    const linkStyle = {fontSize: (typography.h5 == 0 ? 15 : typography.h5) + "px"}

    useEffect(() => {
        setActiveIndex(-1);
    }, [location])

    const setSubmenu = (index: number) => {
        if (activeIndex === index) {
            setActiveIndex(-1)
        } else {
            setActiveIndex(index)
        }

    }

    const getSubmenu = (menuItem: MenuItem) => {
        if (menuItem.subMenu && menuItem.subMenu.length !== 0) {
            return menuItem.subMenu.map(s => {
                return <li className="nav-menu-dropdown-item" key={s.title}>
                    {s.external ? <a href={s.url} style={linkStyle} target={s.newPage ? "_blank" : "_self"}
                                     rel="noreferrer">{s.title}</a> :
                        <NavLink style={linkStyle} to={s.url}>{s.title}</NavLink>}
                </li>
            })
        }
    }
    return <ul className="nav-menu">
        {menuItems.length > 0 ? menuItems.map((m, index) => {
            if (!m.subMenu || m.subMenu.length === 0) {
                return <li className="nav-menu-item" key={m.title}>
                    {m.external ? <a href={m.url} style={linkStyle} target={m.newPage ? "_blank" : "_self"}
                                     rel="noreferrer">{m.title}</a> :
                        <NavLink style={linkStyle} to={m.url}>{m.title}</NavLink>}
                </li>
            } else {
                return <li className="nav-menu-item" key={m.title}>
                    <a href="#" className="nav-menu-dropdown-title" style={linkStyle}
                       onClick={() => setSubmenu(index)}>{m.title} &#x25BE;</a>
                    <ul className={index === activeIndex ? "nav-menu-dropdown open" : "nav-menu-dropdown"}>
                        {getSubmenu(m)}
                    </ul>
                </li>
            }
        }) : <Box sx={{height: "48px"}}/>}
    </ul>
}

const Header = () => {
    const {settings} = useContext(ContentContext);
    const [collapse, setCollapse] = useState(false);
    const [scrollBefore, setScrollBefore] = useState(0);
    const [scrollAfter, setScrollAfter] = useState(0);
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    const handleScroll = useCallback(() => {
        setScrollBefore(scrollAfter)
        setScrollAfter(window.scrollY)
    }, [scrollAfter])

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    const rollUp = (scrollAfter <= scrollBefore) || !matches;

    return <HeaderBox
        sx={{
            top: rollUp ? 0 : "-48px",
            backgroundColor: "white",
            boxShadow: "rgb(237, 238, 242) 0px -1px 0px 0px inset"
        }}>
        <Box sx={{
            maxWidth: "1176px",
            padding: {xs: "0 20px", sm: "0 40px", lg: 0},
            height: {xs: "80px", md: "auto"},
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        }}>
            <a href={settings.logoUrl}>
                <Logo src={settings.logo} sx={{
                    transformOrigin: "bottom",
                    maxHeight: {xs: "50px", md: "70px"},
                    transform: rollUp ? "" : "scale(0.6) translateY(16px)"
                }}/>
            </a>
            {matches && <Box
                className="collapse-menu"
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "end",
                    flexGrow: 1,
                }}>
                <AccessibilityMenu/>
                <Menu/>
            </Box>}
            {!matches && <button className={collapse ? "hamburger-menu open" : "hamburger-menu"}
                                 onClick={() => setCollapse(!collapse)}>
                <span/>
            </button>}
        </Box>
        {!matches && <Box
            className="collapse-menu"
            sx={{
                maxWidth: "1176px",
                padding: {xs: "0 20px", sm: "0 40px", lg: 0},
                margin: "auto",
                display: collapse ? "flex" : "none",
                flexDirection: "column",
                alignItems: "start",
                flexGrow: 1,
            }}>
            <AccessibilityMenu/>
            <Menu/>
        </Box>}
    </HeaderBox>
}

export default Header;
